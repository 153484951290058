<template>
  <v-container fluid>
    <v-sheet rounded class="pa-4">
      <h4>{{title}}</h4>
      <v-form
        ref="form"
        v-model="valid"
        lazy-validation
      >
        <v-row class="mt-5">
          <v-col>
            <phone-number-format
              outlined
              dense
              v-model="form.phoneNo"
              :label="$_strings.userAdmin.PHONE_INPUT_LABEL"
              clearable
              :required="true"
            ></phone-number-format>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-text-field
              outlined
              dense
              :disabled="isEditing"
              v-model="form.email"
              :label="$_strings.userAdmin.EMAIL_INPUT_LABEL"
              :rules="rules.email"
              clearable
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-select
              outlined
              dense
              :disabled="isEditing"
              :label="$_strings.userAdmin.ROLE_INPUT_LABEL"
              :items="roles"
              v-model="form.rolesId"
              item-value="rolesId"
              item-text="rolesName"
              :rules="rules.rolesId"
            ></v-select>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="4" md="3">
            <v-card
              class="mx-auto"
              outlined
            >
              <v-card
                v-if="!form.imageUrl"
                height="200"
                class="d-flex justify-center align-center"
                flat
              >
                <p class="font-italic red--text" v-if="formUpload.isError">*Gambar wajib di upload*</p>
                <p class="font-italic grey--text" v-else>*Belum upload gambar*</p>
              </v-card>
              <v-img
                v-else
                :src="form.imageUrl"
                contain
                aspect-ratio="1"
                height="200"
              >
                <template v-slot:placeholder>
                  <v-row
                    class="fill-height ma-0"
                    align="center"
                    justify="center"
                  >
                    <v-progress-circular
                      indeterminate
                      color="grey lighten-5"
                    ></v-progress-circular>
                  </v-row>
                </template>
              </v-img>
              <v-card-actions class="white">
                <v-btn
                  v-if="!form.imageUrl"
                  color="blue-grey"
                  small
                  block
                  class="pa-4 white--text"
                  @click="$refs['file'].$refs.input.click()"
                >
                  Upload Gambar
                  <v-icon
                    right
                    dark
                  >
                    mdi-cloud-upload
                  </v-icon>
                </v-btn>
                <v-btn
                  v-else
                  color="red"
                  small
                  block
                  class="pa-4 white--text"
                  @click="deleteFile"
                  :loading="formUpload.isLoading"
                >
                  Hapus Foto
                  <v-icon
                    right
                    dark
                  >
                    mdi-trash-can
                  </v-icon>
                </v-btn>
              </v-card-actions>
              <v-file-input
                class="d-none"
                v-model="formUpload.file"
                @change="setFileInput"
                ref="file"
                type="file"
                accept="image/*"
                outlined
                dense
              >
              </v-file-input>
            </v-card>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="auto">
            <v-btn @click="$router.go(-1)">
              {{$_strings.common.BACK}}
            </v-btn>
          </v-col>
          <v-spacer></v-spacer>
          <v-col v-if="userAccess.canCreate" cols="auto">
            <v-btn color="primary" @click="submit">
              {{$_strings.common.SAVE}}
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </v-sheet>
  </v-container>
</template>

<script>

export default {
  name: 'user-admin-create',
  created() {
    const { data } = this.$route.params;
    if (data) {
      this.form = data;
      this.emailDisabled = true;
      this.roles = [{
        rolesName: data.roleRolesName,
        rolesId: data.rolesId,
      }];
    } else if (this.isEditing) {
      this.fetchUserAdminDetail();
    } else {
      this.getRoleList();
    }
  },
  beforeDestroy() {
    if (this.fileQueueDeletion.length && this.isSubmited) {
      Promise.all(this.fileQueueDeletion.map((payload) => this.$_services.uploads.deleteFile(payload)));
    }
  },
  data() {
    return {
      valid: false,
      formUpload: {
        fileType: null,
        file: null,
        base64: null,
        isLoading: false,
        isError: false,
      },
      form: {
        email: '',
        rolesId: null,
        imageUrl: '',
        phoneNo: '',
      },
      rules: {
        email: [
          (value) => {
            if (!value) {
              return this.$_strings.messages.validation.REQUIRED(this.$_strings.companyInformation.EMAIL);
            }
            return this.isEmailValid(value) || this.$_strings.messages.validation.EMAIL;
          },
        ],
        rolesId: [
          (v) => !!v || this.$_strings.messages.validation.REQUIRED(this.$_strings.userAdmin.ROLE_INPUT_LABEL),
        ],
        imageUrl: [
          (v) => !!this.form.imageUrl || this.$_strings.messages.validation.REQUIRED(this.$_strings.userAdmin.IMAGE_INPUT_LABEL),
        ],
      },
      roles: [],
      fileQueueDeletion: [],
      isSubmited: false,
    };
  },
  computed: {
    title() {
      return this.userId ? this.$_strings.userAdmin.EDIT_USERADMIN_PAGE_NAME : this.$_strings.userAdmin.CREATE_USERADMIN_PAGE_NAME;
    },
    userId() {
      return this.$route.params.userId;
    },
    companyId() {
      return this.$route.params.companyId;
    },
    isEditing() {
      return !!this.userId;
    },
  },
  methods: {
    fileUploadValidation(event, fileSizeMin = 1024000) {
      if (!event) return;
      const { size, name } = event;
      const fileType = name.split('.').pop().toLowerCase();
      const fileTypeSupport = ['jpg', 'jpeg', 'png'];
      if (!fileTypeSupport.includes(fileType)) {
        this.$dialog.notify.error('Format file tidak didukung');
        return false;
      }
      if (size > 512000) {
        this.$dialog.notify.error('Ukuran gambar maksimum 500kb.');
        return false;
      }
      return true;
    },
    setFileInput(file) {
      const valid = this.fileUploadValidation(file);
      if (!valid) {
        return false;
      }
      const fileType = file.name.split('.').pop().toLowerCase();
      const reader = new FileReader();
      reader.onload = (e) => {
        const base64Image = e.target.result;
        this.formUpload = {
          ...this.formUpload,
          fileType,
          base64: base64Image,
          isLoading: false,
          isError: false,
        };
        this.form.imageUrl = base64Image;
      };
      reader.readAsDataURL(file);
    },
    async deleteFile() {
      if (!this.formUpload.file && this.form.imageUrl) {
        try {
          this.formUpload.isLoading = true;
          const form = {
            url: this.form.imageUrl,
          };
          this.fileQueueDeletion.push(form);
          this.form.imageUrl = null;
        } finally {
          this.formUpload.isLoading = false;
        }
      } else {
        this.form.imageUrl = null;
        this.formUpload.file = null;
        this.formUpload.base64 = null;
      }
    },
    uploadImage() {
      const dataUser = this.$store.state.user.myUserInfo;
      const { companyId } = dataUser;
      const filePath = `user/${companyId}/`;
      const { base64, fileType } = this.formUpload;
      let newFileName = `${Math.random()}.${fileType}`;
      if (this.form.email) {
        newFileName = this.form.email.replaceAll('.', '_').replace('@', '_').concat(`.${fileType}`);
      }
      return this.$_services.uploads.uploadImage(base64, newFileName, filePath)
        .then((result) => {
          this.form.imageUrl = result.data.url;
          if (this.fileQueueDeletion[0]?.url === result.data.url) {
            this.fileQueueDeletion = [];
          }
          this.formUpload.file = null;
        }).catch(() => {
          this.formUpload.file = null;
        });
    },
    fetchUserAdminDetail() {
      this.$root.$loading.show();
      this.$_services.userAdmins.fetchDetail(this.companyId, this.userId)
        .then((result) => {
          this.form = result.data;
          this.emailDisabled = true;
          this.roles = [{
            rolesName: result.data.roleRolesName,
            rolesId: result.data.rolesId,
          }];
        }).finally((e) => {
          this.$root.$loading.hide();
        });
    },
    async submit() {
      if (!this.$refs.form.validate()) {
        this.$nextTick(() => {
          const el = this.$el.querySelector('.v-messages.error--text:first-of-type');
          this.$vuetify.goTo(el, { offset: 50 });
        });
        return;
      }
      if (this.formUpload.base64) {
        try {
          this.$root.$loading.show();
          await this.uploadImage();
        } catch {
          this.$root.$loading.hide();
          return;
        }
      }
      this.$root.$loading.show();
      this.$_services.userAdmins.createEdit(this.form, this.userId)
        .then(() => {
          this.isSubmited = true;
          if (this.userId) {
            this.$dialog.notify.success(this.$_strings.userAdmin.SUCCESS_EDIT_USER_MESSAGE_TEXT);
          } else {
            this.$dialog.notify.success(this.$_strings.userAdmin.SUCCESS_CREATE_USER_MESSAGE_TEXT);
          }
          this.$router.go(-1);
        }).finally(() => {
          this.$root.$loading.hide();
        });
    },
    getRoleList() {
      this.$_services.userAdmins.roles()
        .then((res) => {
          const rolesFilter = res.data.contents.filter((p) => p.rolesName !== 'SHIPPER GUEST' && p.rolesName !== 'SHIPPER GUEST2');
          this.roles = rolesFilter;
        }).finally(() => {
          this.$root.$loading.hide();
        });
    },
  },
};
</script>
